var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticStyle:{"font-weight":"800","color":"#e53935"}},[_vm._v("จัดการข่าวประกาศ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"mr-4",attrs:{"cols":"4"}},[_c('v-select',{staticClass:"mb-4",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"ค้นหาจากสถานที่"},model:{value:(_vm.searchNews),callback:function ($$v) {_vm.searchNews=$$v},expression:"searchNews"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"color":"#234baa","dark":""}},[_vm._v("ค้นหา")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"mr-4",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"ค้นหา"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer')],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersnews,"items":_vm.items,"search":_vm.search,"searchNews":_vm.searchNews,"items-per-page":10},scopedSlots:_vm._u([{key:"item.imgUrl",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"ma-1",attrs:{"src":item.imgUrl,"width":"120px"}})]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.description)}})]}},{key:"item.activeFlag",fn:function(ref){
var item = ref.item;
return [(item.activeFlag == true)?_c('v-chip',{staticClass:"orange lighten-2"},[_vm._v("ใช้งาน")]):_vm._e(),(item.activeFlag == false)?_c('v-chip',{staticClass:"grey lighten-9 white--text"},[_vm._v("ไม่ใช้งาน")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{on:{"click":function($event){return _vm.Viewnews(item)}}},[_vm._v("mdi-eye")]),_c('v-icon',{on:{"click":function($event){return _vm.Deletenews(item)}}},[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }